<template>
  <vue-plyr>
    <div class="plyr__video-embed">
      <iframe :src="getSource()" allowfullscreen allowtransparency allow="autoplay"></iframe>
    </div>
  </vue-plyr>
</template>

<script>
export default {
  methods: {
    getSource() {
      let video;
      if (this.desktop) {
        video = 'https://www.youtube.com/embed/uoiAPHeA3Cs?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1';
      } else {
        video = 'https://www.youtube.com/embed/9vfaRFkHcVI?iv_load_policy=3&modestbranding=1&playsinline=1&showinfo=0&rel=0&enablejsapi=1';
      }
      return video;
    }
  }
};
</script>
